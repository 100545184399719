(function($) {
    "use strict";

    $.fn.serializeObject = function() {
        var o = '';
        var b = '';
        var a = this.serializeArray();
        $.each(a, function() {
            o = o + this.name + 'wesepth';
            b = b + this.value + 'wesepth';
        });

        return [o, b];
    };

    $.fn.leadsInquiryValidField = function() {
        var _this = this;
        var validation_type = $(this).attr('data-linq-field');

        var type = $(this).attr('type');
        var is_required = $(this).attr('data-linq-req');

        var user_input = $.trim($(this).val());


        /*============================================
        =            Validation functions            =
        ============================================*/
        this.validName = function() {
            var regx = /^[a-zA-Z\.'", ]+$/;
            return regx.test(user_input);
        };

        this.validPhoneUSA = function() {
            var num = parseInt(jQuery(this).val());
            var regx = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;

            if ((user_input == "" || (user_input == "(___) ___-____") && !is_required)) {
                return true;
            }
            else {
                return regx.test(user_input);
            }
        };

        this.validPhone = function() {
            var regx = /^\+?[\ \-0-9]{10,}$/;

            if ((user_input == "" || (user_input == "(___) ___-____") && !is_required)) {
                return true;
            }
            else {
                return regx.test(user_input);
            }
        };

        this.validEmail = function() {
            var regx = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            return regx.test(user_input);
        };

        this.validMessage = function() {
            var regx = /(((http|https|ftp|ftps)\:\/\/)|(www\.))[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/;
            user_input = user_input.replace("'", " ");
            return !regx.test(user_input.toLowerCase());
        };

        this.validAddress = function() {
            var regx = /(((http|https|ftp|ftps)\:\/\/)|(www\.))[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/;
            return !regx.test(user_input.toLowerCase());
        };

        this.validDatetime = function() {
            var test = user_input;
            // test = test + ' ' + $('.appointment-time').val();
            var regx = /^(\d{2}(\/|-))?(\d{2}(\/|-))?(\d{4})/;
            return regx.test(test);
        };

        this.validNewDatetime = function() {
            var test = user_input;
            var regx = /^(\d{2}(\/|-))?(\d{2}(\/|-))?(\d{4})/;
            return regx.test(user_input);
        };

        this.validPredefined = function() {
            if (user_input != "-1" || user_input != "Not Selected") {
                return true;
            }
            return false;
        };

        this.validZipCode = function() {
            if (user_input.length == 5) {
                return true;
            }
            return false;
        };

        this.validPredefinedCheckbox = function() {
            var is_checked = $(this).is(":checked");
            if (is_required && !is_checked) {
                return false;
            }
            return true;
        };

        this.removeError = function() {
            $(this).removeClass('glow');
            $(this).parent().removeClass('hasError');
        };

        this.markError = function() {
            // if (!$(this).next().hasClass('error')) {
            //     $(this).next().addClass('error');
            // }
            // if (!$(this).next().hasClass('active')) {
            //     $(this).next().addClass('active');
            // }
            $(this).parent().addClass('hasError');
        };

        if (is_required && user_input == "") {
            this.markError();
            return false;
        }
        else if (!is_required && user_input == "") {
            this.removeError();
            return true;
        }
        else {
            this.removeError();
        }

        var is_valid = false;
        switch (validation_type) {

            case "name":
            case "fullname":
                {
                    user_input = user_input.replace("'", "");
                    user_input = user_input.replace('"', "");
                    $(this).val(user_input);
                    is_valid = this.validName();

                    break;
                }
            case "phone":
                {
                    is_valid = this.validPhone();
                    break;
                }
            case "phoneusa":
                {
                    is_valid = this.validPhoneUSA();
                    break;
                }
            case "email":
                {
                    is_valid = this.validEmail();
                    break;
                }
            case "message":
                {
                    is_valid = this.validMessage();
                    break;
                }
            case "datetime":
                {
                    is_valid = this.validDatetime();
                    break;
                }
            case "newdatetime":
                {
                    is_valid = this.validNewDatetime();
                    break;
                }
            case "zip":
                {
                    is_valid = this.validZipCode();
                    break;
                }
            case "address":
                {
                    is_valid = this.validAddress();
                    break;
                }
            case "file":
                {
                    is_valid = true;
                    break;
                }
            case "select":
                {
                    if (this.val() != '-1') {
                        is_valid = true;
                    }
                    break;
                }
            case "checkbox":
                {
                    is_valid = this.validPredefinedCheckbox();
                    break;
                }

            case "predefined":
                {
                    if (type == "checkbox") {
                        is_valid = this.validPredefinedCheckbox();
                        break;
                    }

                    is_valid = this.validPredefined();
                    break;
                }

            default:
                {
                    if (this.hasClass('appointment-time')) {
                        if (this.val() != '') {
                            is_valid = true;
                        }
                    }
                }
                break;
        }


        if (!is_valid) {
            this.markError();
        }

        var patt = /^[0-9]{2,5}$/;

        return is_valid;

    };

    $.fn.sendGA = function(category, action, label) {
        ga('send', 'event', category, action, label);
    };

}(jQuery));

(function($) {
    "use strict";

    $.fn.leadsInquiryForm = function() {
        var _this = this;
        var form_id = $(this).attr('data-linq-form');
        var fields_list = $(this).find('[name][data-linq-field]');
        var submit_btn = $(this).find('button[data-linq-btn="form-submit"]');
        var waiting_box = $(this).find('.waiting-box');
        var response_box = $(_this).find('.response-box');
        var response_box_success = $(response_box).find('.success');
        var response_box_error = $(response_box).find('.error');
        var response_box_limit = $(response_box).find('.limit');
        var response_box_error_message = $(response_box_error).find('.response-message');
        var reset_btn_success = $(response_box_success).find('.reset-btn, .close-success-btn');
        var reset_btn_error = $(response_box_error).find('.reset-btn');
        var token = $(this).find('.token-form').val();
        $(fields_list).each(function() {
            $(this).on('blur', function(e) {
                var is_valid = $(this).leadsInquiryValidField();
                var is_required = $(this).attr("data-linq-req") || false;
                var $parent = $(this).closest(".linq-form-field");
                var input_type = $(this).attr("type");

                if(is_valid && !is_required) {
                    if ($parent.hasClass('hasError')) {
                        $parent.removeClass('hasError');
                        $parent.removeClass('linq-form-field-float');
                    } 
                    // else if (input_type === "tel") {
                        // $parent.removeClass('linq-form-field-float');
                    // }
                    else if (input_type === "select") {
                        $parent.removeClass('hasError');
                    }
                }
                else if (is_valid) {

                    if ($parent.hasClass('hasError')) {
                        $parent.removeClass('hasError');
                    }

                } 
                else {

                    if (!$parent.hasClass('hasError')) {
                        $parent.addClass('hasError');
                    }

                }
            });
        });

        this.subscribeEvents = function() {
            $(submit_btn).on('click', this.submitForm);

            $(reset_btn_success).on('click', function() {
                _this.resetForm(true);
            });

            $(reset_btn_error).on('click', function() {
                $.ajax({
                    url: '/index.php?option=com_leadsinquiries&format=ajax&view=click&task=logSubmit',
                    type: 'post',
                    data: {
                        id: form_id,
                        resolution: $(window).width() + 'x' + $(window).height(),
                        viewport: window.screen.width + 'x' + window.screen.height,
                        alias: window.location.pathname,
                        action: 'Viewer clicked Retry sending form'
                    },
                    dataType: 'json'
                });
                _this.resetForm(false);
            });
        };

        this.resetForm = function(clear_fields) {
            $(this).find('.inner.success.active').removeClass('active');
            $(this).find('.inner.limit.active').removeClass('active');
            $(this).find('.inner.error.active').removeClass('active');
            $(this).find('.inner.server-error.active').removeClass('active');
            $(this).find('.response-box.active').removeClass('active');

            if (clear_fields) {
                jQuery(fields_list).val('');
                jQuery(fields_list).parent().removeClass("linq-form-field-float");
                jQuery(fields_list).removeClass("linq-form-field-float-input");
                jQuery(fields_list).next().removeClass('success');
                jQuery(".availability_list").html(''); //clear hours
                if (jQuery('select[data-linq-field="select"]')) {
                    jQuery('select[data-linq-field="select"] option[value=-1]').prop('selected', true);
                    jQuery('select[data-linq-field="select"] option[value="Not Selected"]').prop('selected', true);
                }
            }
        };

        this.submitForm = function(event) {
            event.preventDefault();

            var form_data2 = fields_list.serializeObject();
            leadsFormLog(form_data2[1], form_data2[0], form_id, 'Submit form button pressed');
            var valid_form = true;
            var is_valid = false;
            $(fields_list).each(function() {
                if ($(this).attr('data-linq-field') == 'datetime') {
                    is_valid = $(this).leadsInquiryValidField();
                    is_valid = $('.appointment-time').leadsInquiryValidField();
                } 
                else if ($(this).attr('data-linq-field') == 'select') {
                    $(this).parent().removeClass("hasError");
                    is_valid = true;
                }
                else {
                    if ($(this).attr('data-linq-field') == 'hidden') {
                        is_valid = true;
                    } else {
                        is_valid = $(this).leadsInquiryValidField();
                    }
                }
                if (!is_valid) {
                    valid_form = false;
                }
            });
            if (!valid_form) {
                return false;
            }

            var form_data = new FormData();
            $(fields_list).each(function() {
                var field_name = $(this).attr('name');
                if ($(this).attr('data-linq-field') == 'datetime') {
                    form_data.append(field_name, $(this).val() + ' ' + jQuery('.appointment-time').val());
                } else if ($(this).attr('data-linq-field') == 'file') {
                    var fileInput = document.getElementById($(this).attr('id'));
                    var file = fileInput.files[0];
                    form_data.append(field_name, file);
                } else {
                    form_data.append(field_name, $(this).val());
                }
            });

            // form_data.append('resolution', $(window).width() + 'x' + $(window).height());
            form_data.append('alias', window.location.pathname);

            $(waiting_box).addClass('active');

            var request = $.ajax({
                url: '/?option=com_leadsinquiries&task=ajax.save&id=' + form_id + "&" + token + "=1",
                type: 'POST',
                data: form_data,
                dataType: 'json',
                contentType: false,
                cache: false,
                processData: false
            });

            request.done(function(response) {
                if (response) {
                    console.log(response);
                    if (response.valid == true) {
                        // response_box_message.addClass('active');
                        if (response.ga_category.length > 0) {
                            $(this).sendGA(response.ga_category, response.ga_action, response.ga_label);
                        }
                        $(response_box_success).addClass('active');
                        leadsFormLog( form_data2[1], form_data2[0], form_id, 'Form send success');
                    }
                    if (response.valid == false) {
                        if (response.limit == true) {
                            $(response_box_error_message).html('Sending limit reached. <br />Please try again in a couple of minutes');
                            leadsFormLog( form_data2[1], form_data2[0], form_id, 'Sending limit message');
                        } else {
                            if (response.errors.form != false) {
                                $(response_box_error_message).html('There was a problem with our server. <br />Please try again later');
                                leadsFormLog( form_data2[1], form_data2[0], form_id, 'Server problem error. Very bad!');
                            } else {
                                $(response_box_error_message).html(response.errors.invalid_fields);
                                leadsFormLog( form_data2[1], form_data2[0], form_id, 'Invalid fields from server side');
                            }
                        }
                        response_box_error.addClass('active');
                    }
                }
            });

            request.fail(function(jqXHR, textStatus) {
                console.log(textStatus);
                $(response_box_error).addClass('active');
                $(response_box_error_message).html('There was a problem with our server. <br />Please try again later');
                console.log('FAIL');
                console.log(jqXHR);
                console.log(textStatus);
                leadsFormLog( form_data2[1], form_data2[0], form_id, 'Viewer got a critical error');
            });

            request.always(function(response) {
                $(waiting_box).removeClass('active');
                $(response_box).addClass('active');
            });
        };

        this.init = function() {
            this.subscribeEvents();
        };

        this.init();

    };

    function updateText(event) {
        var input = jQuery(this);
        var val = input.val();
        if (!input.hasClass('appointment-time') && !input.hasClass('inputfile')) {
            if (!input.hasClass('linq-form-field-float')) {
                input.parent().addClass("linq-form-field-float");
                input.addClass("linq-form-field-float-input");
            }
        }
    }

    function updateTextOut(event) {

        var input = jQuery(this);
        var val = input.val();
        if (!input.hasClass('appointment-time') && !input.hasClass('inputfile')) {
            if (val == "" && input.parent().hasClass('linq-form-field-float')) {
                input.parent().removeClass("linq-form-field-float");
                input.removeClass("linq-form-field-float-input");
            }
        }
    }

    var step = 25;
    var scrolling = false;

    function leadsFormLog(value, name, id, action) {
        $.ajax({
            url: '/index.php?option=com_leadsinquiries&format=ajax&view=click&task=logSubmit',
            type: 'post',
            data: {
                value: value,
                name: name,
                id: id,
                resolution: $(window).width() + 'x' + $(window).height(),
                viewport: window.screen.width + 'x' + window.screen.height,
                alias: window.location.pathname,
                action: action
            },
            dataType: 'json'
        });
    }


    $(document).ready(function($) {
        var inputsa = document.querySelectorAll('.inputfile');
        Array.prototype.forEach.call(inputsa, function(input) {
            var label = input.nextElementSibling,
                labelVal = label.innerHTML;
            input.addEventListener('focus', function() {
                input.classList.add('has-focus');
            });
            input.addEventListener('blur', function() {
                input.classList.remove('has-focus');
            });
            input.addEventListener('change', function(e) {
                var fileName = '';
                if (this.files && this.files.length > 1)
                    fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                else
                    fileName = e.target.value.split('\\').pop();

                if (fileName) {
                    if (fileName.length > 17) {
                        fileName = fileName.substr(0, 18) + '...';
                    }
                    label.querySelector('span').innerHTML = fileName;
                } else {
                    label.innerHTML = labelVal;
                }
            });
        });


        $('select[data-linq-field="select"]').on('change', function() {
            var $this = $(this);
            if (!$this.val()) {
                $this.addClass('noValue');
            } else {
                $this.removeClass('noValue');
            }
        });

        $("input[data-linq-field*='datetime']").on('click', function(e) {
            jQuery(this).attr('readonly', 'true');
            e.preventDefault();
            setTimeout(function() {
                jQuery("input[data-linq-field*='datetime'").removeAttr('readonly');
            }, 1000);
        });

        $(".appointment-time").on('click', function(e) {
            jQuery(this).attr('readonly', 'true');
            e.preventDefault();
            setTimeout(function() {
                jQuery(".appointment-time").removeAttr('readonly');
            }, 1000);
        });


        var fields_list_appointment = $(this).find('[name][data-linq-field]');

        $('*[data-linq-form-mode="ajax"]').each(function() {
            $(this).leadsInquiryForm();
        });
        jQuery(".linq-form-field input").on("change keyup keydown focus click", updateText);
        jQuery(".linq-form-field input").on('blur', updateTextOut);

        jQuery(".linq-form-field textarea").on("change keyup keydown focus click", updateText);
        jQuery(".linq-form-field textarea").on('blur', updateTextOut);

        $("input[data-linq-field='zip']")
            .on('keypress', function(e) {
                var zip = $(this);
                if (zip.val().length > 4) {
                    zip.val(zip.val().slice(0, 4));
                }
            });

        $("input[data-linq-field='phone']")
            .on('keypress', function(e) {
                var zip = $(this);
                if (zip.val().length > 10) {
                    zip.val(zip.val().slice(0, 10));
                }
            });

        $("input[data-linq-field='phoneusa']").mask("(999) 999-9999", {
            reverse: true
        });


    });
}(jQuery));
